<template>
  <div class="reginster_container">
    <div class="top_box">
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="注册账号"
          name="first"
        >
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
          >
            <el-form-item
              label=""
              prop="user_name"
            >
              <el-input
                v-model="form.user_name"
                placeholder="用户名"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item
              label=""
              prop="mobile"
            >
              <el-input
                v-model="form.mobile"
                placeholder="手机号"
                maxlength="11"
                prefix-icon="el-icon-mobile-phone"
              ></el-input>
            </el-form-item>

            <el-form-item
              label=""
              prop="sms_code"
            >
              <div class="code_img_box">
                <el-input
                  v-model="form.sms_code"
                  placeholder="验证码"
                  prefix-icon="el-icon-circle-check"
                ></el-input>
                <div class="code_img">
                  <el-button
                    :disabled="disableCodeBtn"
                    type="primary"
                    @click="getPhoneCode"
                  >{{codeBtnText}}</el-button>
                </div>
              </div>
            </el-form-item>

            <el-form-item
              label=""
              prop="user_pass"
            >
              <el-input
                show-password
                v-model="form.user_pass"
                placeholder="密码"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>

            <el-button
              type="primary"
              @click="register"
            >确 定</el-button>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bottom_box">
      <p @click="$emit('changeBox',1)">去登录</p>
      <p></p>
    </div>
  </div>
</template>

<script>
import { getSmsCodeApi } from '../../api/common'
import { registerApi } from '../../api/login.js'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var pattern = /^(1[3|4|5|7|8|9][0-9])\d{8}$/
        // this.disabledBtn = !pattern.test(this.mobile)
        if (pattern.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确手机号'))
        }
      }
    }
    return {
      timer: null,
      disableCodeBtn: false,
      codeBtnText: '获取验证码',
      activeName: 'first',
      form: {
        user_name: '',
        mobile: '',
        user_pass: '',
        sms_code: ''
      },
      rules: {
        user_name: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 2, max: 8, message: '长度在 2 到 8 位', trigger: 'blur' }
        ],
        user_pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 18, message: '长度在 6 到 18 位', trigger: 'blur' }
        ],
        mobile: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        sms_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  destroyed () {
    clearInterval(this.timer)
  },
  // 组件方法
  methods: {
    getPhoneCode () {
      this.$refs.form.validateField('mobile', async (valid) => {
        console.log(valid)
        if (valid) return
        this.disableCodeBtn = true
        const res = await getSmsCodeApi({
          mobile: this.form.mobile,
          type: 1
        })
        if (res && res.code === 0) {
          console.log(123)
          this.codeBtnText = 60
          this.timer = setInterval(() => {
            console.log(this.codeBtnText)
            this.codeBtnText = this.codeBtnText - 1
            if (+this.codeBtnText === 0) {
              clearInterval(this.timer)
              this.codeBtnText = '获取验证码'
              this.disableCodeBtn = false
            }
          }, 1000)
        } else {
          this.disableCodeBtn = false
        }
      })
    },
    register () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        const res = await registerApi(this.form)
        if (res && res.code === 0) {
          this.$message.success('注册成功')
          this.$emit('changeBox', 1)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.reginster_container {
  height: 100%;
  width: 100%;
  .top_box {
    height: 85%;
    border-bottom: 1px solid #a8b0ca;
    .el-form {
      width: 90%;
      margin: 0 auto;
      // margin-top: 10px;

      .el-button {
        width: 100%;
      }

      .code_img_box {
        display: flex;
        justify-content: space-between;
        .el-input {
          width: 60%;
        }
        .code_img {
          width: 35%;
          // background-color: pink;
        }
      }

      /deep/.el-input__inner {
        padding-left: 40px;
        color: #fff;
        background-color: transparent;
        &:focus {
          border-color: #cfd2da;
        }
      }
      /deep/ .el-input__icon {
        font-size: 20px;
      }
    }
  }
  .bottom_box {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    padding: 0 15px;
    p {
      user-select: none;
      cursor: pointer;
    }
  }

  /deep/.el-tabs__item {
    color: #a7a8ad;
  }
  /deep/.el-tabs__item.is-active {
    color: #fff;
  }
  /deep/.el-tabs__active-bar {
    background-color: #fff;
  }
  /deep/.el-tabs__nav-wrap::after {
    background-color: transparent;
  }
}
</style>
